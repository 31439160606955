import { useCallback, useEffect } from "react";

export const useCancelOnEscHook = (handleEsc: Function) => {
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      handleEsc();
    }
  }, [handleEsc]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    }

  }, [escFunction]);
}