import React from "react";
import { BetEditorDriver } from "../../types/Driver";
import {
  BaseWrapper,
  MainAction,
  MainStatic,
  NonSelectableDriverRow,
  PositionIndicator,
  SelectedDriverWrapper,
  SubAction
} from "./BetEditorRows";
import { BetEditorListAPI } from "./RaceResultEditor";
import { IconArrowBarDown, IconX } from "@tabler/icons";
import { SimpleTooltip } from "../ui/SimpleTooltip";
import { useCancelOnEscHook } from "../util/cancel-on-esc-hook";


export const BetEditorEditMode: React.FC<{ selectedDriverId: number, drivers: BetEditorDriver[], api: BetEditorListAPI }> = (props) => {
  useCancelOnEscHook(() => api.onSelectDriver(null));

  const {selectedDriverId, api, drivers} = props;

  const resolveWrapper = (driver: BetEditorDriver, position: number) => {
    return position < 10
      ? (
        <BaseWrapper key={driver.id}>
          <MainAction
            onClick={() => api.onUpdatePosition(position)}>
            <PositionIndicator>{position + 1}</PositionIndicator>
            {driver.short_name}
          </MainAction>
        </BaseWrapper>
      )
      : <NonSelectableDriverRow key={driver.id}>{driver.short_name}</NonSelectableDriverRow>;
  }

  return (
    <>
      {drivers.map((driver, i) =>
        driver.id === selectedDriverId
          ? (
            <SelectedDriverWrapper key={driver.id}>
              <MainStatic>
                {i < 10 && <PositionIndicator>{i + 1}</PositionIndicator>}
                <span>{driver.short_name}</span>
              </MainStatic>

              <SubAction onClick={() => api.onRemoveFromTop10()}>
                <SimpleTooltip content="Remove from top 10">
                  <IconArrowBarDown height={"12px"} />
                </SimpleTooltip>
              </SubAction>
              <SubAction onClick={() => api.onSelectDriver(null)}>
                <SimpleTooltip content="Cancel">
                  <IconX height={12} />
                </SimpleTooltip>
              </SubAction>
            </SelectedDriverWrapper>
          ) : resolveWrapper(driver, i))}
    </>
  )
}
