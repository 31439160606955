const moveItemTo = (arr: Array<any>, fromIndex: number, toIndex: number) => {
  const currentItem = arr[fromIndex];
  const restOfItems = arr.filter((item, i) => i !== fromIndex);

  return [
    ...restOfItems.slice(0, toIndex),
    currentItem,
    ...restOfItems.slice(toIndex)
  ];
};

const indexOfObject = (arr: [], value: any, key='id') => {
  return arr.findIndex((element, index, array) => {
    return element[key] === value;
  })
};

export {
  moveItemTo,
  indexOfObject,
}