import { createAsyncThunk } from "@reduxjs/toolkit";

export const loginUserAction = createAsyncThunk(
  'login/loginUser',
  async (userId, thunkAPI) => {
    console.log('Well fuck me');
    const response = await fetch('http://localhost:8000/login/', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        // IF this is missing, the backend sends a CORS error!!!!!!!!!!! Can be empty string...
        'X-CSRFToken': "",
      },
      body: new URLSearchParams("email=janne.kanerva@iki.fi&password=janne")
    });

    return response.json();
  }
)
