import { useLoginUserMutation } from "../store/bootstrap-endpoints";
import {useState} from "react";

export const LoginScreen = () => {
  const [loginUser, result] = useLoginUserMutation();

  const [credentials, setCredentials] = useState({
    email: "janne.kanerva@iki.fi",
    password: ""
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    loginUser({
      email: credentials.email,
      password: credentials.password,
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <input type="email" value={credentials.email} onChange={(e) => setCredentials(state => ({
        ...state,
        email: e.target.value,
      }))} />
      <input type="password" value={credentials.password} onChange={(e) => setCredentials(state => ({
        ...state,
        password: e.target.value,
      }))} />
      <button type="submit">LOGIN</button>
    </form>
  )
}