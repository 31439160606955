import React from "react";
import { BetEditorDriver } from "../../types/Driver";
import { BaseWrapper, MainAction, PositionIndicator, SubAction } from "./BetEditorRows";
import { BetEditorListAPI } from "./RaceResultEditor";
import { SimpleTooltip } from "../ui/SimpleTooltip";
import { IconClock, IconFlag } from "@tabler/icons";
import styled from "styled-components";

interface Props {
  drivers: BetEditorDriver[];
  api: BetEditorListAPI;
}

type SelectedActionType = {
  $isSelected: boolean;
}

const SelectableAction = styled(SubAction)<SelectedActionType>`
  ${props => `color: ${props.$isSelected ? '#00e3b0' : '#586674'}`}
`;

export const BetEditorListMode: React.FC<Props> = ({drivers, api}) => {
  const resolveWrapper = (driver: BetEditorDriver, position: number) => {
    return position < 10
      ? (
        <BaseWrapper key={driver.id}>
          <MainAction onClick={() => api.onSelectDriver(driver.id)}>
            <PositionIndicator>{position + 1}</PositionIndicator>
            {driver.short_name}
          </MainAction>

          <SelectableAction onClick={() => api.onSelectFastest(driver.id)} $isSelected={driver.isFastest}>
            <SimpleTooltip content="Fastest lap">
              <IconClock size={16} strokeWidth={2} />
            </SimpleTooltip>
          </SelectableAction>

          <SelectableAction onClick={() => api.onSelectPole(driver.id)} $isSelected={driver.isPole}>
            <SimpleTooltip content="Qualifying winner">
              <IconFlag size={16} strokeWidth={2}/>
            </SimpleTooltip>
          </SelectableAction>
        </BaseWrapper>
      )
      : (
        <BaseWrapper key={driver.id}>
          <MainAction onClick={() => api.onSelectDriver(driver.id)}>{driver.short_name}</MainAction>
        </BaseWrapper>
      );
  }

  return (
    <>
      {drivers.map((driver, i) => resolveWrapper(driver, i))}
    </>
  );
}
