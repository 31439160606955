import { useParams } from "react-router-dom";

export const useParamsNumber = (key: string) => {
  const value = useParams();
  const result = Number(value[key]);

  if (Number.isNaN(result)) {
    throw Error("Invalid number conversion in route parameters.");
  }

  return result;
}