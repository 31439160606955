import { baseApi } from "./api-endpoints";
import { Driver } from "../types/Driver";
import { RaceEditorResponse } from "./RaceEditorResponse";

const raceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRaceBet: builder.query<any, number>({
      query: (raceId) => `bet/${raceId}/`,
      transformResponse: (response: RaceEditorResponse) => {
        return {
          status: response.status,
          isOpen: response.is_open,
          isLate: response.is_late,
          race: response.race,
          drivers: response.drivers.map((driver: Driver) => ({
            ...driver,
            isFastest: driver.id === response.fastest?.id,
            isPole: driver.id === response.pole?.id,
          }))
        };
      }
    }),
    saveBet: builder.mutation({
      query(data) {
        console.log('DATA', data);
        const {raceId, ...rest} = data;
        return {
          url: `/bet/${raceId}/`,
          method: 'POST',
          body: rest,
        }
      }
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetRaceBetQuery,
  useSaveBetMutation,
} = raceApi;
