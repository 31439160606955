import { RaceResultEditor } from "../components/bet/RaceResultEditor";
import { useParamsNumber } from "../api/route-utils";
import { useGetRaceBetQuery } from "../store/race-endpoints";
import styled from "styled-components";

const BetRaceContainer = styled.div`
  max-width: 400px;
  padding: 16px;
`;


export const BetRaceScreen = () => {
  const raceId = useParamsNumber("raceId");

  const {data, isLoading} = useGetRaceBetQuery(raceId);
  console.log('data', isLoading, data);

  if (isLoading) {
    return <div>Loading ...</div>
  }

  const {drivers, isLate, race} = data;

  return (
    <BetRaceContainer>
      <RaceResultEditor isLate={isLate} race={race} drivers={drivers} />
    </BetRaceContainer>
  );
}