import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  borderRadius: '5px',
  backgroundColor: '#020914',

  backgrounds: {
    background: "#020914",
    content: "#131B24",
  },

  colors: {
    main: '#D7D9E0',
    secondary: 'magenta',
  },
};

export { theme };