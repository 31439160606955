import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as process from "process";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers, { getState }) => {
    console.log('Base URL: ', process.env.REACT_APP_BACKEND_URL);
    return headers;
  },
  credentials: "include",  // THIS IS VITAL!!! Without this the cookies will not be sent.
});

export const baseApi = createApi({
  baseQuery,
  tagTypes: ['Login'],
  endpoints: () => ({}),
});
