import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { BetRaceScreen } from "./screens/BetRaceScreen";
import { PrivateRoute } from "./auth/PrivateRoute";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Lato, sans-serif;
    background-color: ${props => props.theme.backgrounds.background};
  }
`;

function FrontpageScreen() {
  return <div>frontpage</div>;
}

function App() {
  return (
    <>
      <GlobalStyle/>

      <Routes>
        <Route path="/" element={<PrivateRoute/>}>
          <Route path="frontpage" element={<FrontpageScreen/>}/>
          <Route path="bet/:raceId/" element={<BetRaceScreen/>}/>
          <Route
            path="*"
            element={
              <main style={{padding: "1rem"}}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
