import React, { useEffect, useState } from "react";
import { BetEditorDriver, Race } from "../../types/Driver";
import { BetEditorListMode } from "./BetEditorListMode";
import { BetEditorEditMode } from "./BetEditorEditMode";
import { moveItemTo } from "../../utils/array-utils";
import styled from "styled-components";
import { useSaveBetMutation } from "../../store/race-endpoints";
import { current } from "@reduxjs/toolkit";
import {Button} from "../Button";

interface RaceResultEditorProps {
  isLate: boolean;
  race: Race;
  drivers: BetEditorDriver[];
}

export type PositionUpdate = {
  onUpdatePosition: (position: number) => void,
  onRemoveFromTop10: () => void,
}
export type PositionSelect = {
  onSelectDriver: (driverId: number | null) => void,
}

export type PositionActions = {
  onSelectFastest: (driverId: number) => void,
  onSelectPole: (driverId: number) => void,
}

export type BetEditorListAPI = PositionUpdate & PositionSelect & PositionActions;


function isBetValid(drivers: BetEditorDriver[]) {
  const hasFastest = drivers.filter(driver => driver.isFastest).length > 0;
  const hasPole = drivers.filter(driver => driver.isPole).length > 0;

  return hasFastest && hasPole;
}

const ErrorWrapper = styled.div<{$isValid: boolean}>`
  ${props => !props.$isValid && `border: 2px solid #ff003b; border-radius: 6px;`}
  ${props => props.$isValid && 'border: 2px solid transparent;'}
`;

enum PenaltyType {
  EUROS = "penalty_euros",
  POINTS = "penalty_points",
}

const useEditor = (race: Race, drivers: BetEditorDriver[]) => {
  const [saveBet, iDontKnow] = useSaveBetMutation();
  const [selectedDriverId, setSelectedDriverId] = useState<number|null>(null);
  const [editorDrivers, setEditorDrivers] = useState([...drivers]);
  const isValid = isBetValid(editorDrivers);
  const [penaltyType, setPenaltyType] = useState(PenaltyType.EUROS);

  useEffect(() => {
    if (isValid) {
      const data = {
        pole_id: editorDrivers.filter(driver => driver.isPole)[0].id,
        fastest_id: editorDrivers.filter(driver => driver.isFastest)[0].id,
        top_10_ids: editorDrivers.slice(10).map(driver => driver.id)
      };

      saveBet({raceId: race.id, ...data});
    }
  }, [race.id, saveBet, isValid, editorDrivers]);

  const handleUpdatePosition = (position: number) => {
    if (selectedDriverId) {
      const originalIndex = editorDrivers.map((driver: BetEditorDriver) => driver.id).indexOf(selectedDriverId);
      const updatedDriverList = moveItemTo(editorDrivers, originalIndex, position);

      if (position > 10) {
        // The driver was removed from the top 10 -> we need to clear fastest and pole also as
        // it is not allowed to bet those outside 10.
        updatedDriverList[position] = {
          ...updatedDriverList[position],
          isFastest: false,
          isPole: false,
        };
      }

      setEditorDrivers(updatedDriverList);
    }

    setSelectedDriverId(null);
  }

  const handleSelectExtra = (attributeName: "isFastest" | "isPole") => (driverId: number) => {
    setEditorDrivers(drivers => drivers.map(driver => ({
      ...driver,
      [attributeName]: (driver.id === driverId ? !driver[attributeName] : false),
    })));
  }

  const api: BetEditorListAPI = {
    onSelectDriver: (driverId) => setSelectedDriverId(driverId),
    onUpdatePosition: (position) => handleUpdatePosition(position),
    onRemoveFromTop10: () => handleUpdatePosition(editorDrivers.length - 1),
    onSelectFastest: handleSelectExtra('isFastest'),
    onSelectPole: handleSelectExtra('isPole'),
  };

  const togglePenaltyType = () => {
    setPenaltyType(currentType => {
      return currentType === PenaltyType.EUROS ? PenaltyType.POINTS : PenaltyType.EUROS;
    });
  }

  return {
    selectedDriverId,
    isValid,
    editorDrivers,
    handleSelectExtra,
    handleUpdatePosition,
    api,
    penaltyType,
    togglePenaltyType,
  }
}

const PenaltyBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  
  
  & > button {
    font-size: 24px;
    background-color: #222e3c;
    border: 1px solid ${props => props.theme.backgrounds.background};
    color: white; /* White text */
    padding: 10px 24px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
  }  
  & > button:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  & > button:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  
  & > button:not(:last-child) {
    border-right: none;
  }
  
  .selected {
    background-color: #475562;
  }
`;

const ConfirmSubmission = () => {
  const [isConfirmed, setConfirmed] = useState(false);

  return (
    <>
      {!isConfirmed && <Button style={{backgroundColor: 'grey'}} onClick={() => setConfirmed(true)}>Bet LATE</Button>}
      {isConfirmed && <Button style={{backgroundColor: 'green'}}>Click to confirm!</Button>}
    </>
  );
}

export const RaceResultEditor: React.FC<RaceResultEditorProps> = ({race, isLate, drivers}) => {
  const {selectedDriverId, togglePenaltyType, penaltyType, isValid, editorDrivers, api} = useEditor(race, drivers);

  return (
    <>
      <h1>{race.name}</h1>

      {isLate && <PenaltyBox>
        <button onClick={togglePenaltyType} className={penaltyType === PenaltyType.EUROS ? "selected" : ""}>-5€</button>
        <button onClick={togglePenaltyType} className={penaltyType === PenaltyType.POINTS ? "selected" : ""}>1/2 points</button>
      </PenaltyBox>}

      <ErrorWrapper $isValid={isValid}>
        {isLate && <ConfirmSubmission />}

        {
          selectedDriverId === null &&
          <BetEditorListMode
            drivers={editorDrivers}
            api={api}
          />

        }

        {
          selectedDriverId !== null &&
          <BetEditorEditMode
            api={api}
            drivers={editorDrivers}
            selectedDriverId={selectedDriverId}
          />
        }

      </ErrorWrapper>
    </>
  );
}