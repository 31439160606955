import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { loginReducer } from "./login/login_reducer";
import { baseApi } from "./api-endpoints";

const apiMiddlewares = [
  baseApi.middleware,
];

export const store = configureStore({
  reducer: {
    login: loginReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiMiddlewares)
  ,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
