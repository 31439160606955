import React from "react";
import { PositionSelect } from "./RaceResultEditor";
import { BetEditorDriver } from "../../types/Driver";
import styled from "styled-components";

export const PositionIndicator = styled.span`
  display: inline-block;
  width: 16px;
  text-align: right;
  padding-right: 8px;
  font-weight: 700;
`;

export const BaseWrapper = styled.div`
  display: flex;
  align-items: center;
  
  background-color: ${props => props.theme.backgrounds.content};
  color: ${props => props.theme.colors.main};
  margin: 3px;
  
  border-radius: 3px;
  
  :hover {
    filter: brightness(85%);
  }
`;

export const SelectedDriverWrapper = styled(BaseWrapper)`
  display: flex;
  background-color: #303d4a;
  color: white;
  margin-left: 24px;
  opacity: 1!important;
  padding: 8px;
`;

export const BaseAction = styled.button`
  background: inherit;
  
  border: 0;
  padding: 8px;
  margin: 0;
  
  height: 100%;
  
  font: inherit;
  width: 100%;
  text-align: left;
  color: inherit;
  
  :hover {
    cursor: pointer;
  }
`;

export const MainAction = styled(BaseAction)`
  flex: 8;
`;

export const SubAction = styled(BaseAction)`
  flex: 1;
  text-align: center;
  align-items: center;
  padding: 0 8px;
  
  font-size: 0.9em;
  
  :hover {
    filter: brightness(85%);
  }
`;

export const MainStatic = styled.div`
  flex: 8;
`;

export const NonSelectableDriverRow = styled(BaseWrapper)`
  color: #333d48;
  flex: 8;
  opacity: 1!important;
  padding: 8px;
`;

export const SelectableDriverRow: React.FC<{ driver: BetEditorDriver, api: PositionSelect }> = (props) => {
  const {driver, api} = props;

  return (
    <BaseWrapper>
      <MainAction onClick={() => api.onSelectDriver(driver.id)}>{driver.short_name}</MainAction>
      <SubAction>A</SubAction>
      <SubAction>B</SubAction>
    </BaseWrapper>
  );
}
