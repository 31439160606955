import { baseApi } from "./api-endpoints";

interface BootstrapResponse {
  user: {
    id: number,
    email: string,
  }
}

const bootstrapApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBootstrap: builder.query<BootstrapResponse, void>({
      providesTags: ['Login'],
      query: () => `bootstrap/`,
    }),
    loginUser: builder.mutation<any, {email: string, password: string}>({
      invalidatesTags: ["Login"],
      query: (data) => ({
        url: 'login/',
        method: 'POST',
        body: data,
      })
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBootstrapQuery,
  useLoginUserMutation,
} = bootstrapApi;