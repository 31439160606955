import React from "react";
import { LoginScreen } from "../screens/LoginScreen";
import { Outlet } from "react-router-dom";
import { useGetBootstrapQuery } from "../store/bootstrap-endpoints";
import styled from "styled-components";

const PageWrapper = styled.div`
  // height: 100%;
  // background-color: ${props => props.theme.backgrounds.background};
  color: ${props => props.theme.colors.main};
`;

export const PrivateRoute: React.FC<any> = (props) => {
  const {isLoading, data} = useGetBootstrapQuery();

  if (isLoading) {
    return (
      <PageWrapper>
        <div>Checking if logged in</div>
      </PageWrapper>
    );
  }

  if (data?.user?.id) {
    return <PageWrapper><Outlet /></PageWrapper>
  }


  return <PageWrapper><LoginScreen /></PageWrapper>
}