import styled from "styled-components";

export const Button = styled.button`
  border: 0;
  border-radius: 8px;
  padding: 8px;
  
  font-family: inherit;
  color: inherit;
  background-color: inherit;
  cursor: pointer;
`;
